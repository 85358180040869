import React from "react";
// Components
import Wrapper from "../components/Wrapper";
import Seo from "../components/Seo";
import Contents from "../components/Contents";

const steps = [
    'StepWho',
    'StepReach',
    'StepWheels',
    'StepWeight',
    'StepAvailability',
    'StepZip',
    'StepSearch',
    'StepPrescription',
    'StepMail',
    'StepName',
    'StepNumber',
    'StepDone',
]

const Version2 = () => {
    return (
        <Wrapper> <Seo title="Elektromobilvergleich" description="Vergleichen und bis zu 3.600€ sparen!"/>
            <Contents heading={'Jetzt kostenlose Probefahrt sichern!'} subheading={'Jetzt ausfüllen und bis zu 3600€ sparen.'} steps={steps} version={'v2'} />
        </Wrapper>
    )
}

export default Version2
